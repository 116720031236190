import React from "react";
import { useSelector } from "react-redux";
import ModalHeader from "./components/ModalHeader";
import ModalButtons from "./components/ModalButtons";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { css } from "@emotion/css";
import ModalButtonModel from "public_basics/models/ModalButtonModel";
import { useSnackbar } from "notistack";
import { selectIsDialogFullscreen } from "basics/slices/userSettingsSlice";

export default function CustomModal(props) {
  const { closeSnackbar } = useSnackbar();
  const isFullscreen =
    useSelector(selectIsDialogFullscreen) && props.enableFullscreen;

  const className =
    css`
      padding-left: 0 !important;
    ` + (props.className ? " " + props.className : "");
  const bodyClassName =
    css`
      word-wrap: anywhere;
    ` + (props.bodyClassName ? " " + props.bodyClassName : "");

  const forwardedProps = { ...props };
  delete forwardedProps.customButtons;
  delete forwardedProps.title;
  delete forwardedProps.open;
  delete forwardedProps.onSave;
  delete forwardedProps.isValid;
  delete forwardedProps.enableFullscreen;
  delete forwardedProps.closeButtonText;
  delete forwardedProps.saveButtonText;
  delete forwardedProps.closeButtonDataCy;
  delete forwardedProps.saveButtonDataCy;
  delete forwardedProps.saveOptions;
  delete forwardedProps.modalButtons;
  delete forwardedProps.className;
  delete forwardedProps.bodyClassName;
  delete forwardedProps.disabled;
  delete forwardedProps.additionalSettings;
  delete forwardedProps.addSettings;

  const onClose = () => {
    closeSnackbar();
    props.onClose();
  };

  return (
    <Modal
      show={props.open}
      onHide={onClose}
      animation={false}
      centered
      size={props.size}
      fullscreen={!!isFullscreen}
      className={className}
      backdrop={props.backdrop}
      onKeyDown={props.onKeyDown}
      dialogClassName="modal-fullscreen-sm-down"
      {...forwardedProps}
    >
      <ModalHeader
        onClose={onClose}
        enableFullscreen={props.enableFullscreen}
        isFullscreen={isFullscreen}
        addSettings={props.addSettings}
        additionalSettings={props.additionalSettings}
      >
        {props.title}
      </ModalHeader>
      <Modal.Body className={bodyClassName}>{props.children}</Modal.Body>
      {props.modalButtons ? (
        props.modalButtons
      ) : (
        <ModalButtons
          onSave={
            props.onSave
              ? () => {
                  closeSnackbar();
                  props.onSave();
                }
              : null
          }
          onClose={onClose}
          isValid={props.isValid}
          customButtons={props.customButtons}
          saveButtonText={props.saveButtonText}
          closeButtonText={props.closeButtonText}
          closeButtonDataCy={props.closeButtonDataCy}
          saveButtonDataCy={props.saveButtonDataCy}
          saveOptions={props.saveOptions}
          disabled={props.disabled}
        />
      )}
    </Modal>
  );
}

CustomModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  enableFullscreen: PropTypes.bool,
  onSave: PropTypes.func,
  isValid: PropTypes.bool,
  saveButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  closeButtonDataCy: PropTypes.string,
  saveButtonDataCy: PropTypes.string,
  customButtons: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.string,
  backdrop: PropTypes.oneOf(["static", false, true]),
  size: PropTypes.oneOf(["sm", "lg", "xl"]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onKeyDown: PropTypes.func,
  modalButtons: PropTypes.element,
  saveOptions: PropTypes.arrayOf(PropTypes.instanceOf(ModalButtonModel)),
  bodyClassName: PropTypes.string,
  disabled: PropTypes.bool,
  addSettings: PropTypes.bool,
};
