import { path } from "public_entrymask/info";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import LayoutPopoverSpeedDial from "./components/LayoutPopoverSpeedDial";
import ChangeLayoutNameDialog from "./components/ChangeLayoutNameDialog";
import LayoutTypes from "../../constants/LayoutTypes";
import AddLayoutDialog from "./components/AddLayoutDialog";
import DevextremeIcon from "../primitives/DevextremeIcon";
import {
  ConfirmDialog,
  PositionedDropdown,
  Tooltip,
} from "public_basics/components";
import { Col, Dropdown, Row } from "react-bootstrap";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Building, PersonFill, PlusCircleFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {
  useChangeLayoutDataMutation,
  useChangeLayoutNameMutation,
  useDeleteLayoutMutation,
  useGetAvailableLayoutsQuery,
} from "../../services/layoutsApi";

const AddIcon = styled(PlusCircleFill)`
  color: var(--bs-success);
`;

const MinHeightDropdownItem = styled(Dropdown.Item)`
  min-height: 40px;
`;

export default function LayoutPopover(props) {
  const [layoutToDelete, setLayoutToDelete] = useState(null);
  const [layoutToChangeName, setLayoutToChangeName] = useState(null);
  const [layoutToChangeData, setLayoutToChangeData] = useState(null);
  const [showAddLayoutDialog, setShowAddLayoutDialog] = useState(false);
  const { t } = useTranslation(["layouts", "common"]);
  const navigate = useNavigate();

  const { currentData: layouts, isLoading } = useGetAvailableLayoutsQuery({
    entity: props.entity,
  });

  const [changeLayoutData] = useChangeLayoutDataMutation();
  const [changeLayoutName] = useChangeLayoutNameMutation();
  const [deleteLayout] = useDeleteLayoutMutation();

  const getLayoutIcon = (type) => {
    if (type === LayoutTypes.enterpriseLayout)
      return <Building size={24} data-cy={"building-icon"} />;
    if (type === LayoutTypes.userLayout)
      return <PersonFill size={24} data-cy={"person-icon"} />;
    return null;
  };

  const getLayoutSecondaryAction = (layout) => {
    return (
      <LayoutPopoverSpeedDial
        onDelete={() => setLayoutToDelete(layout)}
        showSave={props.enableSaveOption}
        onChangeLayoutName={() => setLayoutToChangeName(layout)}
        onChangeLayoutData={() => setLayoutToChangeData(layout)}
        active={props.currentLayout && props.currentLayout.id === layout.id}
      />
    );
  };

  const renderListItems = () => {
    const items = layouts
      ? layouts.map((layout) => {
          return (
            <div key={"layout-" + layout.guid} data-cy={"layoutItem"}>
              <MinHeightDropdownItem
                active={
                  props.currentLayout && props.currentLayout.id === layout.id
                }
                className={"pt-0 align-content-center d-grid"}
                onClick={() => {
                  props.onSetCurrentLayout(layout);
                  props.onClose();
                  if (props.bookId) navigate(`${path}/${props.bookId}`);
                }}
              >
                <Row>
                  <Col xs={2} className={"align-content-center d-grid"}>
                    {getLayoutIcon(layout.type)}
                  </Col>
                  <Tooltip text={layout.name}>
                    <Col
                      className={
                        "align-content-center overflow-hidden d-inline-block text-truncate " +
                        css`
                          max-width: 15rem !important;
                        `
                      }
                      xs={8}
                    >
                      {layout.name}
                    </Col>
                  </Tooltip>
                </Row>
              </MinHeightDropdownItem>
              {getLayoutSecondaryAction(layout)}
            </div>
          );
        })
      : [];
    items.unshift(
      <MinHeightDropdownItem
        key={"layout-default"}
        onClick={() => {
          props.onSetCurrentLayout({ default: true });
          props.onClose();
          if (props.bookId) navigate(`${path}/${props.bookId}`);
        }}
        active={!props.currentLayout || props.currentLayout.default}
        className={"align-content-center d-grid pt-0"}
      >
        <Row>
          <Col xs={2} className={"align-content-center d-grid"}>
            <DevextremeIcon icon={"deletetable"} />
          </Col>
          <Col xs={8}>{t("standard", { ns: "common" })}</Col>
        </Row>
      </MinHeightDropdownItem>,
    );

    if (isLoading) {
      items.push(renderSkeleton());
    }
    if (props.enableSaveOption) {
      items.push(<Dropdown.Divider key={"layout-divider"} />);
      items.push(
        <Dropdown.Item
          key={"layout-add"}
          onClick={() => setShowAddLayoutDialog(true)}
          data-cy={"addNewLayout"}
        >
          <Row>
            <Col xs={2}>
              <AddIcon size={24} />
            </Col>
            <Col xs={8}>{t("add")}</Col>
          </Row>
        </Dropdown.Item>,
      );
    }
    return items;
  };

  const onDeleteLayout = () => {
    if (props.currentLayout && props.currentLayout.id === layoutToDelete.id)
      props.onSetCurrentLayout({ default: true });
    deleteLayout({ layout: layoutToDelete });
    setLayoutToDelete(null);
  };

  const renderSkeleton = () => (
    <div key={"layout-skeleton"} data-cy={"layoutSkeleton"}>
      <MinHeightDropdownItem className={"pt-0 align-content-center d-grid"}>
        <Row>
          <Col xs={2} className={"align-content-center d-grid"}>
            <Skeleton
              className={css`
                border-radius: 50% !important;
              `}
              width={15}
              height={15}
            />
          </Col>
          <Col
            className={
              "align-content-center overflow-hidden d-inline-block text-truncate " +
              css`
                max-width: 15rem !important;
              `
            }
            xs={8}
          >
            <Skeleton />
          </Col>
        </Row>
      </MinHeightDropdownItem>
    </div>
  );

  return (
    <>
      <PositionedDropdown
        position={props.position}
        onClose={props.onClose}
        order={2}
      >
        {renderListItems()}
      </PositionedDropdown>
      <ConfirmDialog
        show={!!layoutToDelete}
        onClose={() => setLayoutToDelete(null)}
        onConfirm={onDeleteLayout}
        text={
          layoutToDelete && t("delete", { layoutName: layoutToDelete.name })
        }
        header={t("confirm-delete", { ns: "common" })}
      />
      <ConfirmDialog
        show={!!layoutToChangeData}
        onClose={() => setLayoutToChangeData(null)}
        onConfirm={() => {
          changeLayoutData({
            layout: layoutToChangeData,
            body: { data: props.newLayoutData },
          });
          setLayoutToChangeData(null);
        }}
        text={
          layoutToChangeData &&
          t("overwrite", { layoutName: layoutToChangeData.name })
        }
        header={t("overwrite-header")}
      />
      <ChangeLayoutNameDialog
        layoutToChange={layoutToChangeName}
        onClose={() => setLayoutToChangeName(null)}
        onSave={(newName) => {
          changeLayoutName({
            layout: layoutToChangeName,
            body: { name: newName },
          });
          setLayoutToChangeName(null);
        }}
      />
      <AddLayoutDialog
        newLayoutData={props.newLayoutData}
        open={showAddLayoutDialog}
        onClose={() => setShowAddLayoutDialog(false)}
        entity={props.entity}
      />
    </>
  );
}

LayoutPopover.propTypes = {
  onSetCurrentLayout: PropTypes.func.isRequired,
  currentLayout: PropTypes.object,
  position: PropTypes.object,
  enableSaveOption: PropTypes.bool,
  newLayoutData: PropTypes.object,
  entity: PropTypes.object.isRequired,
};
