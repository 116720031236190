import PropTypes from "prop-types";
import React from "react";
import { ExclamationTriangle } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "./ConfirmDialog";

ConfirmDeleteDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  text: PropTypes.string,
};

function ConfirmDeleteDialog(props) {
  const { t } = useTranslation("common");

  return (
    <ConfirmDialog
      {...props}
      header={
        <>
          <ExclamationTriangle className="text-danger me-3 align-self-center" />
          {t("confirm-delete")}
        </>
      }
    />
  );
}

export default ConfirmDeleteDialog;
