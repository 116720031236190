import PropTypes from "prop-types";
import { ImesLogo } from "public_basics/img/Images";
import shadowed from "public_basics/shadowed";
import React from "react";
import { Modal } from "react-bootstrap";
import ModalSettingsGear from "./ModalSettingsGear";

export default function ModalHeader(props) {
  return (
    <div>
      <Modal.Header
        closeButton
        className={props.isFullscreen ? "bg-primary" : ""}
        closeVariant={props.isFullscreen ? "white" : null}
      >
        <Modal.Title
          className={"w-100 d-flex " + (props.isFullscreen ? "text-light" : "")}
        >
          {!!props.isFullscreen && (
            <img
              src={ImesLogo}
              alt="Logo"
              className={"me-3 " + shadowed}
              height="40"
            />
          )}
          {props.children}
        </Modal.Title>
        {(props.addSettings || props.enableFullscreen) && (
          <ModalSettingsGear
            additionalSettings={props.additionalSettings}
            isFullscreen={props.isFullscreen}
            enableFullscreen={props.enableFullscreen}
          />
        )}
      </Modal.Header>
    </div>
  );
}

ModalHeader.propTypes = {
  onClose: PropTypes.func,
  enableFullscreen: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  addSettings: PropTypes.bool,
};
