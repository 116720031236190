import { Gear } from "react-bootstrap-icons";
import React, { useState } from "react";
import ShowSettingsPopover from "./ShowSettingsPopover";
import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setIsDialogFullscreen } from "basics/slices/userSettingsSlice";

export default function ModalSettingsGear(props) {
  const { t } = useTranslation("basics");
  const getButtonVariant = () =>
    props.isFullscreen ? "outline-primary" : "outline-primary";
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  const setFullscreen = (value) => {
    setShowPopover(false);
    dispatch(setIsDialogFullscreen(value));
  };

  const [showPopover, setShowPopover] = useState(false);

  const handleClick = () => {
    setShowPopover(!showPopover);
  };

  const displayView = (
    <div>
      {props.enableFullscreen && (
        <ButtonGroup className={"ms-auto me-5 d-none d-sm-inline-flex"}>
          <Button
            variant={getButtonVariant()}
            active={!!props.isFullscreen}
            onClick={() => setFullscreen(true)}
          >
            {t("fullscreen")}
          </Button>
          <Button
            variant={getButtonVariant()}
            active={!props.isFullscreen}
            onClick={() => setFullscreen(false)}
          >
            {t("large")}
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
  const gearElementStyles = {
    // Initial styles for the "Gear" element if LargeScreen
    color: isHovered ? "black" : "grey",
    cursor: "pointer", // Change the cursor to a pointer on hover
  };

  const gearElementStylesFullScreen = {
    // Initial styles for the "Gear" element if FullScreen
    color: isHovered ? "white" : "lightgrey",
    cursor: "pointer", // Change the cursor to a pointer on hover
  };

  return (
    <ShowSettingsPopover
      showPopover={showPopover}
      setShowPopover={setShowPopover}
      additionalSettings={props.additionalSettings}
      displayView={displayView}
      isFullView={props.isFullscreen}
    >
      <Gear
        className={"me-2"}
        size={24}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={
          props.isFullscreen ? gearElementStylesFullScreen : gearElementStyles
        }
        onClick={handleClick}
        data-cy={"Gear"}
      />
    </ShowSettingsPopover>
  );
}
