import { keysToCamel } from "basics/utils/utils";

export default class DataGridDataColumnDefinitionModel {
  id;
  key;
  caption;
  dataType;
  cellRender;
  groupIndex;
  sortOrder;
  fixedValueList;
  width;
  allowFiltering;
  allowHeaderFiltering;
  allowSorting;
  allowGrouping;
  allowExporting;

  constructor(id, key, caption, dataType, fixedValueList) {
    this.id = id;
    this.key = key;
    this.caption = caption;
    this.dataType = dataType;
    this.fixedValueList = keysToCamel(fixedValueList);
  }
}
