import Select, { components } from "react-select";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const CustomNoOptionsMessage = (props) => {
  const { t } = useTranslation("entry-mask");

  return (
    <components.NoOptionsMessage {...props}>
      {t("no-options")}
    </components.NoOptionsMessage>
  );
};

const CustomMenuList = (props) => (
  <components.MenuList
    {...props}
    innerProps={{ "data-cy": "customizedSelect" }}
  />
);

const CustomDropdownIndicator = (props) => (
  <components.DropdownIndicator
    {...props}
    innerProps={{ ...props.innerProps, "data-cy": "openSelectDropdown" }}
  />
);

const CustomClearIndicator = (props) => (
  <components.ClearIndicator
    {...props}
    innerProps={{ ...props.innerProps, "data-cy": "clearSelect" }}
  />
);

const CustomMultiValueContainer = (props) => (
  <components.MultiValueContainer
    {...props}
    innerProps={{ ...props.innerProps, "data-cy": "selectMultiValue" }}
  />
);

export default function CustomizedSelect(props) {
  const customStyles = {
    control: (provided, state) => {
      const style = { ...provided };
      style.borderColor = "var(--bs-border-color) !important";
      if (state.isFocused) {
        style.borderColor = "#92c0e2 !important";
        style.boxShadow = "0 0 0 0.25rem rgba(37, 129, 196, 0.25)";
      }
      if (state.selectProps.isInvalid) {
        style.borderColor = "var(--bs-form-invalid-border-color) !important";
        style.backgroundImage =
          "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e\")";
        if (state.isFocused) {
          style.boxShadow = "0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25)";
        }
      }
      if (state.selectProps.isValid) {
        style.borderColor = "var(--bs-form-valid-border-color) !important";
        style.backgroundImage =
          "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e\")";
        if (state.isFocused) {
          style.boxShadow = "0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25)";
        }
      }
      style.backgroundRepeat = "no-repeat";
      style.backgroundSize = "calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)";
      style.backgroundPosition = "right calc(1em + 3.5rem) center";
      style.borderRadius = ".375rem";
      if (state.isDisabled) {
        style.backgroundColor = "var(--bs-secondary-bg)";
      }
      if (!state.hasValue || state.isDisabled)
        style.backgroundPosition = "right calc(1em + 2rem) center";
      return style;
    },
    singleValue: (provided, state) => {
      const style = { ...provided };
      delete style.label;
      style.color = "#495057";
      style.border = "none";
      style.outline = "none";
      style.backgroundColor = "inherit";
      style.width = "calc(100% - 20px)";
      style.cursor = "text";
      if (state.isDisabled) {
        style.width = "calc(100% - 35px)";
      }
      return style;
    },
    container: (provided) => {
      return { ...provided, pointerEvents: "all" };
    },
    indicatorsContainer: (provided) => {
      const style = { ...provided };
      style.cursor = "pointer";
      return style;
    },
    menuPortal: (provided) => {
      // z-index: 1503 is the devextreme popover z-index.
      return { ...provided, zIndex: 1503 };
    },
  };

  return (
    <Select
      {...props}
      value={props.value}
      options={props.options}
      onChange={props.onChange}
      isDisabled={props.disabled}
      styles={
        props.customStyles
          ? _.merge(customStyles, props.customStyles)
          : customStyles
      }
      components={{
        NoOptionsMessage: CustomNoOptionsMessage,
        MenuList: CustomMenuList,
        DropdownIndicator: CustomDropdownIndicator,
        ClearIndicator: CustomClearIndicator,
        MultiValueContainer: CustomMultiValueContainer,
        ...props.components,
      }}
      {...props.validation}
      isClearable={!props.disabled && props.isClearable}
      headline={props.headline || undefined}
      isMulti={props.isMulti}
      placeholder={null}
      closeMenuOnSelect={props.closeMenuOnSelect}
      hideSelectedOptions={props.hideSelectedOptions}
      className={props.className}
      menuPortalTarget={props.portalTarget || document.body}
      menuPosition={"fixed"}
      isOptionDisabled={props.isOptionDisabled}
      getOptionValue={props.getOptionValue}
      getOptionLabel={props.getOptionLabel}
    />
  );
}

CustomizedSelect.defaultProps = {
  isClearable: true,
};

CustomizedSelect.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    ),
  ]),
  options: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  components: PropTypes.any,
  headline: PropTypes.any,
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  customStyles: PropTypes.object,
  isClearable: PropTypes.bool,
  validation: PropTypes.oneOfType([
    PropTypes.shape({ isValid: PropTypes.bool }),
    PropTypes.shape({ isInvalid: PropTypes.bool }),
  ]),
  isOptionDisabled: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
};
