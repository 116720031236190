import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./PictureGallery.scss";
import { ConfirmDialog } from "public_basics/components";
import { useTranslation } from "react-i18next";
import { Trash3 } from "react-bootstrap-icons";
import ImageGallery from "react-image-gallery";
import PictureGalleryItem from "./PictureGalleryItem";

let timestampOfLastFullScreenModeChange = new Date().getTime();

const renderItem = (item) => {
  return (
    <PictureGalleryItem
      url={item.original}
      authenticated={!!item.picture.guid}
      id={item.picture.id}
    />
  );
};

const renderThumbnailItem = (item) => {
  return (
    <PictureGalleryItem
      url={item.thumbnail}
      authenticated={!!item.picture.guid}
      id={item.picture.id}
      thumbnail
    />
  );
};

export default function PictureGallery(props) {
  const { pictures, deleteDisabled } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPicIndex, setCurrentPicIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [eventListenerAppended, setEventListenerAppended] = useState(false);
  //Used to prevent multiple requests for the same picture.
  const { t } = useTranslation("entry-mask");

  const galleryRef = useRef();

  useEffect(() => {
    let touches = [];

    const onDoubleClick = (e) => {
      e.preventDefault();
      toggleFullScreenMode();
    };

    const onTouchEnd = (e) => {
      const maxDoubleTouchDelay = 300;
      touches.push(new Date().getTime());
      touches = touches.filter(
        (touch) => touch + maxDoubleTouchDelay >= new Date().getTime(),
      );
      if (touches.length >= 2) {
        // Double tap
        e.preventDefault();
        toggleFullScreenMode();
        touches = [];
      }
    };

    const toggleFullScreenMode = () => {
      // In case "doubleClick" and "doubleTap" are recognized simultaneously, only the first one is processed.
      if (timestampOfLastFullScreenModeChange + 300 < new Date().getTime()) {
        // was the last mode change more than 300ms ago?
        galleryRef.current.toggleFullScreen(!isFullscreen);
        timestampOfLastFullScreenModeChange = new Date().getTime();
      }
    };

    const addEventListenerToImageGallery = () => {
      // Dependent on the browser, device and operation mode either dblclick, touchend or both events are fired.
      // In case both events are fired, "timestampOfLastFullScreenModeChange" is necessary to prevent processing them both simultaneously.
      galleryRef.current.imageGallery.current.addEventListener(
        "dblclick",
        onDoubleClick,
      );
      galleryRef.current.imageGallery.current.addEventListener(
        "touchend",
        onTouchEnd,
      );
    };

    if (pictures.length === 0) {
      // ImageGallery exists if there is at least one picture.
      // After re-instantiating, the event handlers have to be
      setEventListenerAppended(false);
    }

    if (galleryRef.current && !eventListenerAppended) {
      addEventListenerToImageGallery();
      setEventListenerAppended(true);
    }
  }, [galleryRef, pictures, isFullscreen, eventListenerAppended]);
  useEffect(() => {
    if (currentPicIndex >= pictures.length) setCurrentPicIndex(0);
  }, [pictures, currentPicIndex]);

  const renderDeleteButton = () => {
    return (
      <button
        className={
          "delete-button image-gallery-play-button image-gallery-icon" +
          (deleteDisabled ? " d-none" : "")
        }
        data-cy="deleteImageBtn"
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <Trash3 className={"delete-button-icon"} />
      </button>
    );
  };

  const onDeletePicture = () => {
    props.onDelete(pictures[currentPicIndex].picture);
    setCurrentPicIndex(0);
    setShowDeleteModal(false);
  };
  if (pictures.length > 0) {
    return (
      <>
        <ImageGallery
          additionalClass={
            !isFullscreen ? "border rounded image-gallery-gray" : ""
          }
          ref={galleryRef}
          items={pictures}
          startIndex={currentPicIndex}
          showBullets={true}
          renderPlayPauseButton={renderDeleteButton}
          onSlide={setCurrentPicIndex}
          onScreenChange={setIsFullscreen}
          useBrowserFullscreen={false}
          renderItem={renderItem}
          renderThumbInner={renderThumbnailItem}
        />
        <ConfirmDialog
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={onDeletePicture}
          header={t("delete-picture-header")}
          text={t("delete-picture-text")}
        />
      </>
    );
  }
  // noinspection JSConstructorReturnsPrimitive
  return null;
}

PictureGallery.propTypes = {
  onDelete: PropTypes.func.isRequired,
  pictures: PropTypes.arrayOf(
    PropTypes.shape({
      picture: PropTypes.object,
      original: PropTypes.string,
      thumbnail: PropTypes.string,
    }),
  ),
  deleteDisabled: PropTypes.bool.isRequired,
};
