import React from "react";
import PropTypes from "prop-types";
import CustomModal from "basics/components/CustomModal/CustomModal";
import { css } from "@emotion/css";
import { useTranslation } from "react-i18next";

export default function ConfirmDialog(props) {
  const { t } = useTranslation("common");
  return (
    <CustomModal
      open={props.show}
      onClose={props.onClose}
      closeButtonText={t("no")}
      saveButtonText={t("yes")}
      onSave={props.onConfirm}
      data-cy={"ConfirmDialog"}
      saveButtonDataCy={"confirmButton"}
      closeButtonDataCy={"cancelButton"}
      backdropClassName={css`
        z-index: 1055 !important;
      `}
      title={props.header}
    >
      {props.text}
    </CustomModal>
  );
}

ConfirmDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  header: PropTypes.node,
  text: PropTypes.string,
};
